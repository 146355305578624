<template>
	<div class="box-card padding20">
		<div class="title">
			<!-- {{ translateTitle("账户") }} -->
			<Header />
		</div>
		<!-- <Headers /> -->
		<div class="card-detail-title">

			<topHeader />
			<div class="card-detail-title-btn">
				<el-button @click="addvisibleVirtual" :loading="getLoading">
          {{ translateTitle("获得虚拟卡") }}</el-button
        >
				<el-button @click="add(2)">
					{{ translateTitle("分配实体卡") }}</el-button>
			</div>
		</div>
		<div v-loading="tableLoading" class="card-detail">
			<el-row :gutter="20">
				<el-col v-for="(item, index) in tableData" :span="8">
					<!-- @click.stop="selectCard(item)" -->
					<div :class="item.id === ruleForm.id?'select':''">
						<img class="img-bg" :src="item.cardImage" alt="" />
						<div class="content-bottom">
							<div class="left">
								<div class="line1">
									{{ item.type == "physical" ? translateTitle("物理") : translateTitle("虚拟") }}
								</div>
								<div v-if="item.frontCardNo" class="line2">
									{{ item.frontCardNo | mobileFilter }}
								</div>
							</div>
						</div>
						<div class="content-left">
						</div>
						<div class="content-right">
							<div class="left">
								<img v-if="item.cardStatus == 'active'" src="../../assets/imgs/icon_tishi_bai.png"
									alt="" />
								<img v-else src="../../assets/imgs/icon_tishi_hong.png" alt="" />
								<span :class="item.cardStatus == 'active' ? 'color1' : 'color2'">{{
                  item.cardStatus == "active"
                    ? translateTitle("活跃中")
                    : item.cardStatus == "suspended"
                    ? translateTitle("暂时封锁")
                    : translateTitle("永久封锁")
                }}</span>
							</div>
							<div class="right">
								
								<img @click.stop="changeShow(item)" width="26"
									src="../../assets/imgs/ico_yinhangka_set@2x.png" alt="" />
								<div v-if="item.show" class="message-popover">
									<p @click.stop="handDetail(item, 0)">
										{{ translateTitle("卡片详情") }}
									</p>
									<p v-if="item.cardStatus == 'active'&&item.type == 'physical'"
										@click.stop="handDetail(item, 1)">
										{{ translateTitle("设置密码") }}
									</p>
									<p v-if="item.cardStatus == 'freeze'" @click.stop="handDetail(item, 'active')">
										{{ translateTitle("激活") }}
									</p>
									<p v-if="item.cardStatus == 'suspended'" @click.stop="handDetail(item, 'unblock')">
										{{ translateTitle("解封") }}
									</p>
									<p v-if="item.cardStatus == 'active'"
										@click.stop="handDetail(item, 'temporaryBlock')">
										{{ translateTitle("封卡")}}
									</p>
									<!-- <p v-if="item.cardStatus == 'active'"
										@click.stop="handDetail(item, 'permanentBlock')">
										{{ translateTitle("封卡") }} - {{ translateTitle("永久") }}
									</p> -->
								</div>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<el-dialog custom-class="custom-dialog" title="" :visible.sync="detailDialog" :close-on-click-modal="false"
			width="35%">
			<img class="bg" :src="choiceData.backImage" alt="" />
			<img class="closeBtn" @click="closeBtn" src="../../assets/imgs/ico_yinhangka_close@2x.png" alt="" />
			<div class="line1" style="color: black;font-size: 24px;position: absolute;top: 44%;left: 50%;">
				{{ choiceData.ccv}}
			</div>
			<div class="content-bottom">
				<div class="left">
					<div v-if="choiceData.cardNumber" class="line2">
						{{ choiceData.cardNumber | mobileFilter }}
					</div>
				</div>
				
			</div>
			
			<div class="content-right">
				<span>{{ choiceData.createAt }}</span>
			</div>
		</el-dialog>
		<DialogPop width="650px" :title="translateTitle('实体卡')" :visible="visible" :closeBtn="false"
			:btnloading="btnloading" :sureText="translateTitle('分配卡片')" @onClose="onClose" @onSure="onSure('ruleForm')">
			<div class="change-form">
				<el-form label-position="top" :model="ruleForm" status-icon :rules="rules" ref="ruleForm"
					label-width="100px" class="demo-ruleForm" hide-required-asterisk>
					
					<el-form-item :label="translateTitle('背面代理号码')" prop="physicalCardNo">
						<el-input v-model="ruleForm.physicalCardNo" clearable
							:placeholder="translateTitle('输入12位数字')"></el-input>
					</el-form-item>
			
				</el-form>
			</div>
		</DialogPop>
		<DialogPop width="650px" :title="translateTitle('虚拟卡')" :visible="visibleVirtual" :closeBtn="false"
			:btnloading="btnloading" :sureText="translateTitle('分配卡片')" @onClose="visibleVirtual = false" @onSure="onSureVirtual('ruleForm')">
			<div class="change-form">
				<el-form label-position="top" :model="ruleForm" status-icon :rules="rules" ref="ruleForm"
					label-width="100px" class="demo-ruleForm" hide-required-asterisk>
					
					<el-form-item :label="translateTitle('开卡费用')">
						<p>{{cardFeelist.virtualFee}} USDT</p>
					</el-form-item>
				
					<el-form-item :label="translateTitle('支付方式')" prop="id">
						<el-select
						 class="svg-container"
						  clearable
						  v-model="ruleForm.id"
						  :placeholder="translateTitle('请选择')"
						  @change="changeCoinName(ruleForm.id)"
						>
						 <template #prefix>
						    <span class="flex">
						      <img
						        :src="choiceCoin.coinLogo || require('@/assets/imgs/icon_usdt@2x.png')"
						        width="24"
						        height="24"
						      />
						    </span>
						  </template>
						  <el-option
						    v-for="item in option"
						    :key="item.id"
						    :label="item.coinName"
						    :value="item.id"
						  >
						  <div class="disal">
						      <img
						        :src="item.coinLogo"
						        width="24"
						        height="24"
						      />
						      <span class="select-item">{{ item.coinName }}</span>
						    </div>
						  </el-option>
						</el-select>
					</el-form-item>
					
				</el-form>
			</div>
		</DialogPop>
		<DialogPop width="650px" :title="translateTitle('设置密码')" :visible="visible1" :closeBtn="false"
			:btnloading="btnloading" :sureText="translateTitle('确认')" @onClose="onClose1"
			@onSure="onSure1('ruleForm1')">
			<div class="change-form">
				<el-form label-position="top" :model="ruleForm1" status-icon :rules="Rules1" ref="ruleForm1"
					label-width="100px" class="demo-ruleForm" hide-required-asterisk>
					<el-form-item :label="translateTitle('设置密码')" prop="pin">
						<el-input show-password v-model.trim="ruleForm1.pin" onfocus="this.removeAttribute('readonly');"
							:placeholder="translateTitle('请输入新密码')" type="text" clearable>
						</el-input>
					</el-form-item>
					<el-form-item :label="translateTitle('请二次确认密码')" prop="pin1">
						<el-input show-password v-model.trim="ruleForm1.pin1"
							onfocus="this.removeAttribute('readonly');" :placeholder="translateTitle('请再次输入新密码')"
							type="text" clearable>
						</el-input>
					</el-form-item>
				</el-form>
			</div>
		</DialogPop>
	</div>
</template>

<script>
	import Headers from "../../components/accountHeader/index.vue";
	import topHeader from "../../components/topHeader/index"
	import {
		userCards,
		assignPCard,
		getVCard,
		opCard,
		setPin,
		cardFee,
		cardFeeCoins
	} from "@/api/card.js";
	import {
		translateTitle
	} from "@/utils/i18n";
	export default {
		components: {
			Headers,
			topHeader
		},
		filters: {
			mobileFilter(val) {
				return val.replace(/\D/g, '').replace(/....(?!$)/g, '$& ')
				// return val.replace(reg,'$1......$2')
			},
		},
		data() {
			const validatePassword = (rule, value, callback) => {
				if ("" === value) {
					callback(new Error(this.translateTitle("密码不能为空")));
				} else if (!/^(-)?[0-9]+([.][0-9]{1,})?$/.test(value)) {
					callback(new Error(this.translateTitle("请输入4位数字")));
				} else if (!/^[0-9]{4}$/.test(value)) {
					callback(new Error(this.translateTitle("请输入4位数字")));
				} else callback();
			};
			const validatePasswordSecond = (rule, value, callback) => {
				if ("" === value) {
					callback(new Error(this.translateTitle("密码不能为空")));
				} else if (!/^(-)?[0-9]+([.][0-9]{1,})?$/.test(value)) {
					callback(new Error(this.translateTitle("请输入4位数字")));
				} else if (!/^[0-9]{4}$/.test(value)) {
					callback(new Error(this.translateTitle("请输入4位数字")));
				} else if (this.ruleForm1.pin != value) {
					callback(new Error(this.translateTitle("两次验证码必须一致")));
				} else callback();
			};
			const validateFrontCardNo = (rule, value, callback) => {
				if ("" === value) {
					callback(new Error(this.translateTitle("密码不能为空")));
				} else if (!/^[0-9]{12}$/.test(value)) {
					callback(new Error(this.translateTitle('输入12位数字')));
				} else callback();
			};
			const validatefrontCardNo = (rule, value, callback) => {
				if ("" === value) {
					callback(new Error(this.translateTitle("密码不能为空")));
				} else if (!/^[0-9]{16}$/.test(value)) {
					callback(new Error(this.translateTitle('输入16位数字')));
				} else callback();
			};
			const validatecvv = (rule, value, callback) => {
				if ("" === value) {
					callback(new Error(this.translateTitle("密码不能为空")));
				} else if (!/^[0-9]{3}$/.test(value)) {
					callback(new Error(this.translateTitle('输入3位数字')));
				} else callback();
			};
			const validatemonth = (rule, value, callback) => {
				if ("" === value) {
					callback(new Error(this.translateTitle("密码不能为空")));
				} else if (!/^[0-9]{2}$/.test(value)) {
					callback(new Error(this.translateTitle('输入2位数字')));
				} else callback();
			};
			const validateyear = (rule, value, callback) => {
				if ("" === value) {
					callback(new Error(this.translateTitle("密码不能为空")));
				} else if (!/^[0-9]{2}$/.test(value)) {
					callback(new Error(this.translateTitle('输入2位数字')));
				} else callback();
			};
			return {
				value1: false,
				size: 10,
				detailDialog: false,
				visible: false,
				visible1: false,
				visibleVirtual:false,
				option:[],
				choiceCoin:{},
				ruleForm: {
					id: "",
					physicalCardNo: "",
					// frontCardNo: ''
					coinName: "USDT",
				},
				ruleForm1: {
					id: "",
					pin: "",
					pin1: "",
				},
				rules: {
					pass: [{
						required: true,
						message: "请输入活动名称",
						trigger: "change"
					}],
					age: [{
						required: true,
						message: "请输入活动名称",
						trigger: "change"
					}],
					frontCardNo: [{
						required: true,
						validator: validatefrontCardNo,
						trigger: "change"
					}],
					cvv: [{
						required: true,
						validator: validatecvv,
						trigger: "change"
					}],
					month: [{
						required: true,
						validator: validatemonth,
						trigger: "change"
					}],
					year: [{
						required: true,
						validator: validateyear,
						trigger: "change"
					}],
					physicalCardNo: [{
						required: true,
						validator: validateFrontCardNo,
						trigger: "change"
					}],
					id: [{
						required: true,
						message:this.translateTitle("请选择支付方式"),
						trigger: "change"
					}],
				},
				tableData: [],
				choiceData: "",
				activeName: "second",
				getLoading: false,
				btnloading: false,
				tableLoading: false,
				Rules1: {
					pin: [{
						required: true,
						trigger: "change",
						validator: validatePassword
					}, ],
					pin1: [{
						required: true,
						trigger: "change",
						validator: validatePasswordSecond
					}, ],
				},
				globalLoading: null,
				cardFeelist:{}
			};
		},
		created() {
			this.getData();
			this.getcardFee()
			this.getcardFeeCoins()
		},
		methods: {
			translateTitle,
			async getData(val) {
				let res = await userCards();
				if (val != '1') {
					this.globalLoading = this.$loading({
						lock: true,
						text: '',
						spinner: '',
						background: 'rgba(255, 255, 255, 0.8)'
					});
				}

				if (res.code == 200) {
					// this.tableLoading = false
					this.tableData = res.data.map((item) => {
						item.show = false;
						item.visible = false;
						return item;
					});
					this.globalLoading.close()
				} else {
					this.$message.error(this.translateTitle(res.msg));
				}
			},
			choiceType(item) {
				item.show = true;
			},
			async getcardFee(){
				let res = await cardFee()
				this.cardFeelist = res.data
			},
			async getcardFeeCoins(){
				let res = await cardFeeCoins()
				this.option = res.data;
			},
			changeCoinName(option) {
			  let obj  = this.option.find(item => item.id === option)
			  // this.choiceCoin.coinName = obj.coinName;
			  this.choiceCoin.coinLogo = obj.coinLogo;
			  this.choiceCoin.amount = obj.amount;
			  // this.choiceCoin.toUsdtRate = obj.toUsdtRate;
			  // this.ruleForm.coinLink = obj.coinLink;
			  // this.ruleForm.coinName = obj.coinName
			  // this.ruleForm.coinId = obj.id
			},
			changeShow(item) {
				this.tableData.forEach(res => {
					if (res.id == item.id) {
						res.show = !res.show;
					} else {
						res.show = false;
					}
				})
				item.visible = true;
			},
			handDetail(item, val) {
				console.log(val)
				if (val == "0") {
					this.choiceData = item;
					item.visible = false;
					this.detailDialog = true;
				} else if (val == "1") {
					this.ruleForm1.id = item.id
					this.visible1 = true;
				} else {
					let params = {
						id: item.id,
						opType: val,
					};
					this.globalLoading = this.$loading({
						lock: true,
						text: '',
						spinner: '',
						background: 'rgba(255, 255, 255, 0.8)'
					});
					opCard(params).then((res) => {
						if (res.code == 200) {
							item.cardStatus = val;
							item.show = false;
							item.visible = false;
							this.$message.success(this.translateTitle("操作成功"));
							this.getData(1);
						} else {
							this.$message.error(this.translateTitle(res.msg));
						}
					}).finally(() => {
						this.globalLoading.close()
					});
				}
			},
			closeBtn() {
				this.detailDialog = false;
			},
			// selectCard(item) {
			//   if(item.cardStatus == 'active') {
			//     // if(item.cardNumber) {
			//     //   this.$message.error(this.translateTitle('已分配'));
			//     // } else {
			//       this.ruleForm.id = item.id
			//     // }

			//   } else {
			//     this.$message.error(this.translateTitle('无效卡片'));
			//   }

			// },
			addvisibleVirtual(){
					this.visibleVirtual = true;
			},
			onSureVirtual(formName){
				if(this.choiceCoin.amount < this.cardFeelist.virtualFee){
					this.$message.error(this.translateTitle("余额不足"));
					return
				}else{
					this.$refs[formName].validate((valid) => {
						if (valid) {
							// this.btnloading = true
							this.globalLoading = this.$loading({
								lock: true,
								text: '',
								spinner: '',
								background: 'rgba(255, 255, 255, 0.8)'
							});
							let data = {
								coinId:this.ruleForm.id
							}
							getVCard(data).then((res) => {
								if (res.code == 200) {
									this.$message.success(this.translateTitle("操作成功"));
									this.getData(1);
									this.visibleVirtual = false;
									this.$refs["ruleForm"].resetFields();
								} else {
									this.$message.error(this.translateTitle(res.msg));
								}
							}).finally(() => {
								// this.btnloading = false
								this.globalLoading.close()
							});
						} else {
							return false;
						}
					});
				}
			},
			add(val) {
				if (val == 2) {
					this.getcardFee()
					if (this.ruleForm.id) {
						this.visible = true;
					} else {
						this.ruleForm.id = ""
						this.visible = true;
						// this.$message.error(this.translateTitle('请选择虚拟卡'));
					}
				} else {
					this.$confirm(this.translateTitle('确认创建虚拟卡'), '', {
						confirmButtonText: this.translateTitle('确定'),
						cancelButtonText: this.translateTitle('取消'),
						customClass: 'custom-message',
						type: '',
						callback: () => {},
						beforeClose: (action, ctx, close) => {
							if (action !== 'confirm') {
								close();
								return;
							}
							this.globalLoading = this.$loading({
								lock: true,
								text: '',
								spinner: '',
								background: 'rgba(255, 255, 255, 0.8)'
							});
							// ctx.confirmButtonLoading = true;
							getVCard().then((res) => {
								if (res.code == 200) {
									this.$message.success(this.translateTitle("操作成功"));
									this.getData(1);
									this.getLoading = false;
									close();
								} else {
									this.$message.error(this.translateTitle(res.msg));
								}
							}).finally(() => {
								// ctx.confirmButtonLoading = false;
								this.globalLoading.close()
							})
						}
					}).finally(() => {})


				}
			},
			onClose() {
				this.$refs["ruleForm"].resetFields();
				this.visible = false;
			},
			onClose1() {
				this.$refs["ruleForm1"].resetFields();
				this.visible1 = false;
			},
			onSure(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// this.btnloading = true
						this.globalLoading = this.$loading({
							lock: true,
							text: '',
							spinner: '',
							background: 'rgba(255, 255, 255, 0.8)'
						});
						assignPCard(this.ruleForm).then((res) => {
							if (res.code == 200) {
								this.$message.success(this.translateTitle("操作成功"));
								this.getData(1);
								this.visible = false;
								this.$refs["ruleForm"].resetFields();
							} else {
								this.$message.error(this.translateTitle(res.msg));
							}
						}).finally(() => {
							// this.btnloading = false
							this.globalLoading.close()
						});
					} else {
						return false;
					}
				});
				// this.visible = true;
			},
			onSure1(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// this.btnloading = true
						this.globalLoading = this.$loading({
							lock: true,
							text: '',
							spinner: '',
							background: 'rgba(255, 255, 255, 0.8)'
						});
						setPin(this.ruleForm1).then((res) => {
							if (res.code == 200) {
								this.$message.success(this.translateTitle("操作成功"));
								this.getData(1);
								this.visible1 = false;
								this.$refs["ruleForm1"].resetFields();
							} else {
								this.$message.error(this.translateTitle(res.msg));
							}
						}).finally(() => {
							// this.btnloading = false
							this.globalLoading.close()
						});
					} else {
						return false;
					}
				});
				// this.visible1 = true;
			},
		},
	};
</script>

<style lang="less" scoped>
	.title {
		font-size: 26px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: bold;
		color: #31363e;
		margin-bottom: 20px;
		text-align: right;
	}

	.card-detail {
		min-height: 400px;
	}

	.card-detail-title {
		font-size: 22px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: bold;
		color: #31363e;
		margin-top: 30px;
		margin-bottom: 30px;
		display: flex;
		justify-content: space-between;
		align-items: end;

		.card-detail-title-btn .el-button {
			width: 262px;
			height: 66px;
			background: #F7F6F5;
			border: 1px solid #F7F6F5;
			border-radius: 17px;
			color: #31363E;
			font-size: 16px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
		}
	}

	.card-detail {
		.el-col-8 .select {
			.img-bg {
				border-color: rgb(79, 137, 220);
			}

			// -webkit-transform: translate3d(0, -2px, 0);
			// transform: translate3d(0, -2px, 0);
			// transform:scale(1.05,1.05)

		}

		.el-col-8 {
			position: relative;
			margin-bottom: 20px;
            width: 28%;
			.img-bg {
				width: 100%;
				// padding: 5px;
				border-radius: 20px;
				//  border: 5px solid#fff;
			}

			.content-bottom {
				position: absolute;
				line-height: 2;
				bottom: 23%;
				left: 12%;
				font-size: 20px;
				font-weight: bold;
				display: flex;
				justify-content: space-between;
				width: 80%;
				align-items: end;

				.right {
					img {
						width: 50px;
					}
				}

				.line1 {
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					font-weight: 400;
					color: rgba(255, 255, 255, 0.8);
					line-height: 16px;
					-webkit-background-clip: text;
					font-size: 16px;
					margin-bottom: 25px;
				}

				.line2 {
					font-size: 24px;
					font-family: Microsoft YaHei-Bold, Microsoft YaHei;
					font-weight: 700;
					color: #FFFFFF;
					line-height: 16px;
					-webkit-background-clip: text;
				}
			}

			.content-left {
				position: absolute;
				left: 50px;
				top: 40px;

				img {
					width: 50px;
				}
			}

			.content-right {
				position: absolute;
				top: 20%;
				right: 6%;
				font-size: 16px;
				display: flex;
				align-items: center;

				.left {
					display: inline-block;

					.color1 {
						color: #ffffff;
					}

					.color2 {
						color: #e85c5c;
					}

					span {
						margin-left: 10px;
						font-size: 14px;
						font-family: Microsoft YaHei-Regular, Microsoft YaHei;
						font-weight: 400;
						line-height: 18px;
						-webkit-background-clip: text;
					}

					img {
						width: 20px;
						vertical-align: middle;
					}
				}

				.right {
					margin-left: 15px;
					display: inline-block;
					position: relative;

					img {
						width: 32px;
					}
				}
			}
		}
	}

	// .change-form {

	//   ::v-deep {
	//     .el-input__inner {
	//       height: 50px !important;
	//       line-height: 50px !important;
	//       border: none !important;
	//     }
	//     .el-form-item__label {
	//       font-size: 16px;
	//       font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	//       font-weight: bold;
	//       color: #b9bfca;
	//     }
	//     .el-checkbox__input.is-checked .el-checkbox__inner,
	//     .el-checkbox__input.is-indeterminate .el-checkbox__inner {
	//       background-color: #06a43e !important;
	//       border-color: #06a43e !important;
	//     }
	//     .el-checkbox__input.is-checked + .el-checkbox__label {
	//       color: #000;
	//     }
	//     .el-checkbox__inner:hover {
	//       border-color: #000;
	//     }
	//     //      .el-checkbox__inner {
	//     //     background-color: #06A43E !important;
	//     //     border-color: #06A43E !important;
	//     // }
	//   }
	// }
	.succ {
		color: #3ad3b9;
	}

	.err {
		color: #d33a3a;
	}

	::v-deep {

		.el-tabs__nav-wrap::after {
			background-color: transparent;
			height: 0;
		}

		.el-tabs__active-bar {
			background: #165BA4;
			height: 3px;
		}

		.el-tabs__item {
			font-size: 22px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: bold;
			color: #b9bfca;
		}

		.el-tabs__item.is-active {
			font-size: 22px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: bold;
			color: #353a42;
		}

		.custom-dialog {
			position: relative;
			border-radius: 36px;
			background: transparent;
			box-shadow: none;

			.el-dialog__header {
				display: none;
			}

			.el-dialog__body {
				padding: 0px;

				.bg {
					width: 100%;
				}
			}

			.closeBtn {
				position: absolute;
				right: -50px;
				top: -50px;
				width: 45px;
			}

			.content-bottom {
				width: 85%;
				position: absolute;
				line-height: 2;
				bottom: 22%;
				left: 3.5%;
				font-size: 34px;
				font-weight: bold;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;

				.line1 {
					font-size: 20px;
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					font-weight: 400;
					color: rgba(255, 255, 255, 0.8);
					line-height: 20px;
					-webkit-background-clip: text;
					margin-bottom: 34px;

					div {
						float: right;
					}

					span {
						color: #15171a;
						margin-left: 10px;
					}
				}

				.line2 {
					font-size: 24px;
					font-family: Microsoft YaHei-Bold, Microsoft YaHei;
					font-weight: 700;
					color: #FFFFFF;
					line-height: 20px;
					-webkit-background-clip: text;
				}

				.right {
					text-align: right;
					font-size: 20px;
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					font-weight: 400;
					color: rgba(255, 255, 255, 0.8);
					line-height: 20px;
					-webkit-background-clip: text;

					div {

						// color: #b9bfca;
						span {
							// color: #fff;
						}
					}

					img {
						width: 70px;
					}
				}
			}

			.content-left {
				position: absolute;
				left: 10%;
				top: 50px;

				img {
					width: 100px;
				}
			}

			.content-right {
				position: absolute;
				bottom: 12%;
				left: 58%;

				// display: flex;
				// align-items: center;
				// justify-content: center;
				// width: 103px;
				// height: 47px;
				// text-align: center;
				// background: #3E8CE5;
				// box-shadow: inset 0px 2px 5px 0px rgba(0,0,0,0.04);
				// border-radius: 5px 5px 5px 5px;
				// opacity: 1;
				span {
					font-size: 20px;
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 23px;
					-webkit-background-clip: text;
				}
			}
		}

	}
</style>
<style>
	.message-popover {
		position: absolute;
		background-color: #fff;
		z-index: 99;
		right: -7px;
		top: 50px;
		padding: 0px !important;
		width: 230px !important;
		text-align: center;
		box-shadow: 3px 16px 13px 0px rgba(233, 238, 240, 0.28) !important;
		border-radius: 17px !important;

	}

	.message-popover:before {
		content: '';
		position: absolute;
		top: -8px;
		right: 14px;
		width: 0;
		height: 0px;
		/* border-style: solid;
        border-width: 5px;
         */
		/* border-color:transparent   #fff   #fff; */
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid #fff;
		/* transform: rotate(-135deg); */
		/* box-shadow:  3px -3px  3px rgba(0, 0, 0, 0.11); */
	}

	.message-popover p {
		height: 78px;
		line-height: 78px;
		/* padding-left: 30px; */
		font-size: 16px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #31363e;
	}

	.message-popover p:hover,
	.message-popover p:active {
		background: #F7F6F5;
	}

	.el-button .el-icon-loading {
		background: url('../../assets/imgs/GIF.gif') center center no-repeat;
		background-size: cover;
		width: 20px;
		height: 13px;
		vertical-align: middle;
		animation: none;
	}

	.el-button .el-icon-loading:before {
		content: "-";
		color: transparent;
		display: block;
	}
</style>